// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as ClientErrors from "@literacyplanet/client-errors";
import * as Auth$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/Auth.bs.js";
import * as Wrap$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/Wrap.bs.js";
import * as Error$LiteracyplanetClientErrors from "../../../../node_modules/@literacyplanet/client-errors/src/Error.bs.js";
import * as Lazy$LiteracyplanetClientSxEntry from "./Lazy.bs.js";
import * as History$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/History.bs.js";
import * as Icons$LiteracyplanetClientSxEntry from "./layouts/Icons.bs.js";
import * as Routes$LiteracyplanetClientSxEntry from "./Routes.bs.js";
import * as WebpDetect$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/WebpDetect.bs.js";
import * as QueryAudios$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryAudios.bs.js";
import * as BookQuery$LiteracyplanetClientSxLibrary from "../../../../node_modules/@literacyplanet/client-sx-library/src/BookQuery.bs.js";
import * as InMemoryStore$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/InMemoryStore.bs.js";
import * as ProRequired$LiteracyplanetClientSxEntry from "./ProRequired.bs.js";
import * as LocaleOverride$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/LocaleOverride.bs.js";
import * as CobraFontQuery$LiteracyplanetClientSxEntry from "./CobraFontQuery.bs.js";
import * as LockingProvider$LiteracyplanetClientSxEntry from "./LockingProvider.bs.js";
import * as QueryAudiosContext$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/QueryAudiosContext.bs.js";
import * as CobraGenericBehaviour$LiteracyplanetClientFlags from "../../../../node_modules/@literacyplanet/client-flags/src/CobraGenericBehaviour.bs.js";
import * as JourneyHeaderStyles$LiteracyplanetClientSxEntry from "./layouts/JourneyHeaderStyles.bs.js";
import * as LibraryBookContext$LiteracyplanetClientSxLibrary from "../../../../node_modules/@literacyplanet/client-sx-library/src/LibraryBookContext.bs.js";
import * as StoryversePanelContext$LiteracyplanetClientSxEntry from "./StoryversePanelContext.bs.js";

var lockedMessage = "You need to finish your assigned Missions to unlock access to other areas.";

function Main$PageRouter(Props) {
  var page = Props.page;
  var state = Props.state;
  var goBack = Props.goBack;
  var redirect = Props.redirect;
  var redirectWithState = Props.redirectWithState;
  var replaceWithState = Props.replaceWithState;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var isItemBlacklisted = Props.isItemBlacklisted;
  var onToggleLocked = Props.onToggleLocked;
  var onToggleProModal = Props.onToggleProModal;
  var pageToUrlWithState = Props.pageToUrlWithState;
  return Routes$LiteracyplanetClientSxEntry.pageToComponent(page, state, goBack, redirect, redirectWithState, replaceWithState, isPageBlacklisted, isItemBlacklisted, onToggleLocked, onToggleProModal, pageToUrlWithState);
}

var PageRouter = {
  make: Main$PageRouter
};

function queryAudios(ids, setSources) {
  return React.createElement(QueryAudios$LiteracyplanetClientFlags.make, {
              ids: ids,
              setSources: setSources
            });
}

function Main$LayoutSelector(Props) {
  var page = Props.page;
  var state = Props.state;
  var goBack = Props.goBack;
  var redirect = Props.redirect;
  var redirectWithState = Props.redirectWithState;
  var replaceWithState = Props.replaceWithState;
  var isPageBlacklisted = Props.isPageBlacklisted;
  var isItemBlacklisted = Props.isItemBlacklisted;
  var onToggleLocked = Props.onToggleLocked;
  var onToggleProModal = Props.onToggleProModal;
  var pageToUrlWithState = Props.pageToUrlWithState;
  var layout = Routes$LiteracyplanetClientSxEntry.pageToLayout(page);
  return React.createElement(React.Suspense, {
              children: React.createElement(StoryversePanelContext$LiteracyplanetClientSxEntry.make, {
                    children: React.createElement(Lazy$LiteracyplanetClientSxEntry.StudentExperienceLayout.make, Lazy$LiteracyplanetClientSxEntry.StudentExperienceLayout.makeProps(redirect, layout, Routes$LiteracyplanetClientSxEntry.pageToBackground(page), page, isPageBlacklisted, React.createElement(QueryAudiosContext$LiteracyplanetClientFlags.Provider.make, {
                                  value: queryAudios,
                                  children: React.createElement(LibraryBookContext$LiteracyplanetClientSxLibrary.Provider.make, {
                                        value: BookQuery$LiteracyplanetClientSxLibrary.use,
                                        children: React.createElement(Main$PageRouter, {
                                              page: page,
                                              state: state,
                                              goBack: goBack,
                                              redirect: redirect,
                                              redirectWithState: redirectWithState,
                                              replaceWithState: replaceWithState,
                                              isPageBlacklisted: isPageBlacklisted,
                                              isItemBlacklisted: isItemBlacklisted,
                                              onToggleLocked: onToggleLocked,
                                              onToggleProModal: onToggleProModal,
                                              pageToUrlWithState: pageToUrlWithState
                                            })
                                      })
                                }), undefined, undefined))
                  }),
              fallback: React.createElement("div", undefined)
            });
}

var LayoutSelector = {
  make: Main$LayoutSelector
};

function dictToQueryString(dict) {
  var queryParams = Belt_Array.map(Js_dict.entries(dict), (function (param) {
            return "" + param[0] + "=" + param[1];
          })).join("&");
  return encodeURI(queryParams.length === 0 ? "" : "?" + queryParams);
}

function makeQueryUrl(page, state) {
  var url = Routes$LiteracyplanetClientSxEntry.pageToUrl(page);
  return url + dictToQueryString(state);
}

function Main(Props) {
  var router = Props.router;
  var $$location = Props.location;
  var path = History$LiteracyplanetClientFlags.makePath(undefined);
  var page = Routes$LiteracyplanetClientSxEntry.pathToPage(path);
  var push = router.push;
  var routeReplace = function (path) {
    return Curry._1(router.replace, path);
  };
  var goBack = router.goBack;
  var redirect = function (page) {
    return Curry._1(push, Routes$LiteracyplanetClientSxEntry.pageToUrl(page));
  };
  var onClickHome = function (param) {
    return Curry._1(push, Routes$LiteracyplanetClientSxEntry.pageToUrl(/* StudentExperience */0));
  };
  var state = Belt_Option.mapWithDefault($$location, {}, (function (r) {
          return r.query;
        }));
  var redirectWithState = function (page, state) {
    return Curry._1(push, makeQueryUrl(page, state));
  };
  var replaceWithState = function (page, state) {
    return Curry._1(router.replace, makeQueryUrl(page, state));
  };
  var customUnleashFeatures = [];
  return React.createElement(InMemoryStore$LiteracyplanetClientFlags.make, {
              children: React.createElement(ClientErrors.ErrorModal, {
                    children: React.createElement(Error$LiteracyplanetClientErrors.App.make, {
                          children: React.createElement(Error$LiteracyplanetClientErrors.Modal.make, {
                                onClickHome: onClickHome,
                                children: (function (param) {
                                    return React.createElement(Auth$LiteracyplanetClientFlags.make, {
                                                routeReplace: routeReplace,
                                                customUnleashFeatures: customUnleashFeatures,
                                                children: React.createElement(LocaleOverride$LiteracyplanetClientFlags.make, {
                                                      children: React.createElement(Wrap$LiteracyplanetClientFlags.Provider.make, {
                                                            children: React.createElement(CobraFontQuery$LiteracyplanetClientSxEntry.make, {
                                                                  children: (function (myFont) {
                                                                      return React.createElement(CobraGenericBehaviour$LiteracyplanetClientFlags.make, {
                                                                                  children: React.createElement(LockingProvider$LiteracyplanetClientSxEntry.make, {
                                                                                        redirect: redirect,
                                                                                        page: page,
                                                                                        children: (function (redirect, onToggleLocked, showLockedModal, isPageBlacklisted, isItemBlacklisted) {
                                                                                            return React.createElement(ProRequired$LiteracyplanetClientSxEntry.make, {
                                                                                                        redirect: redirect,
                                                                                                        page: page,
                                                                                                        children: (function (proRedirect, onToggleProModal, showProRequiredModal) {
                                                                                                            return React.createElement(React.Fragment, undefined, React.createElement(WebpDetect$LiteracyplanetClientFlags.CheckSupport.make, {
                                                                                                                            features: [
                                                                                                                              "Lossy",
                                                                                                                              "Lossless",
                                                                                                                              "Alpha"
                                                                                                                            ]
                                                                                                                          }), React.createElement(Main$LayoutSelector, {
                                                                                                                            page: page,
                                                                                                                            state: state,
                                                                                                                            goBack: goBack,
                                                                                                                            redirect: proRedirect,
                                                                                                                            redirectWithState: redirectWithState,
                                                                                                                            replaceWithState: replaceWithState,
                                                                                                                            isPageBlacklisted: isPageBlacklisted,
                                                                                                                            isItemBlacklisted: isItemBlacklisted,
                                                                                                                            onToggleLocked: onToggleLocked,
                                                                                                                            onToggleProModal: onToggleProModal,
                                                                                                                            pageToUrlWithState: makeQueryUrl
                                                                                                                          }), showLockedModal ? React.createElement("div", {
                                                                                                                              className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalBackground,
                                                                                                                              onClick: (function (param) {
                                                                                                                                  return Curry._1(onToggleLocked, undefined);
                                                                                                                                })
                                                                                                                            }, React.createElement("div", {
                                                                                                                                  className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModal
                                                                                                                                }, React.createElement("div", undefined), React.createElement("div", {
                                                                                                                                      className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalContent
                                                                                                                                    }, React.createElement(Icons$LiteracyplanetClientSxEntry.LockIcon.make, {
                                                                                                                                          className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalIcon
                                                                                                                                        }), React.createElement("div", {
                                                                                                                                          className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalText
                                                                                                                                        }, lockedMessage)), React.createElement(Icons$LiteracyplanetClientSxEntry.CloseIcon.make, {
                                                                                                                                      className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.closeModalIcon
                                                                                                                                    }))) : null, showProRequiredModal !== undefined ? React.createElement("div", {
                                                                                                                              className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalBackground,
                                                                                                                              onClick: (function (param) {
                                                                                                                                  return Curry._1(onToggleProModal, undefined);
                                                                                                                                })
                                                                                                                            }, React.createElement("div", {
                                                                                                                                  className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModal
                                                                                                                                }, React.createElement("div", undefined), React.createElement("div", {
                                                                                                                                      className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalContent
                                                                                                                                    }, React.createElement(Icons$LiteracyplanetClientSxEntry.PersonIcon.make, {
                                                                                                                                          className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalIcon
                                                                                                                                        }), React.createElement("div", {
                                                                                                                                          className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.lockedModalText
                                                                                                                                        }, showProRequiredModal)), React.createElement(Icons$LiteracyplanetClientSxEntry.CloseIcon.make, {
                                                                                                                                      className: JourneyHeaderStyles$LiteracyplanetClientSxEntry.closeModalIcon
                                                                                                                                    }))) : null);
                                                                                                          })
                                                                                                      });
                                                                                          })
                                                                                      }),
                                                                                  myFont: myFont
                                                                                });
                                                                    })
                                                                })
                                                          })
                                                    })
                                              });
                                  })
                              })
                        }),
                    onClickHome: onClickHome
                  })
            });
}

var bookQuery = BookQuery$LiteracyplanetClientSxLibrary.use;

var make = Main;

var $$default = Main;

export {
  lockedMessage ,
  PageRouter ,
  queryAudios ,
  bookQuery ,
  LayoutSelector ,
  dictToQueryString ,
  makeQueryUrl ,
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
